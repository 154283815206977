import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { SlimTemplate } from '../templates/SlimTemplate'

const Iframe = ({ src }) => (
  <iframe width="100%" height="166" scrolling="no" frameBorder="no" {...{ src }} />
)

const SecondPage = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          allFile(filter: { base: { eq: "musik.jpg" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 460) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={({
        allFile: {
          edges: [
            {
              node: {
                childImageSharp: { fluid }
              }
            }
          ]
        }
      }) => {
        return (
          <SlimTemplate>
            <Iframe src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/250061760&amp;auto_play=false&amp;hide_related=false&amp;show_comments=false&amp;show_user=false&amp;show_reposts=false&amp;visual=true" />
            <Iframe src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/250069282&amp;auto_play=false&amp;hide_related=false&amp;show_comments=false&amp;show_user=false&amp;show_reposts=false&amp;visual=true" />
            <Iframe src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/965750473&amp;auto_play=false&amp;hide_related=false&amp;show_comments=false&amp;show_user=false&amp;show_reposts=false&amp;visual=true" />
            <Img critical fluid={fluid} />
          </SlimTemplate>
        )
      }}
    />
  )
}

export default SecondPage
